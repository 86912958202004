import React, { useEffect, useState } from "react";
import Crumbs from "../../components/ordinary/crumbs/crumbs";
import Title from "../../components/ordinary/title/title";
import AsideNews from "../../components/simple/aside-news/aside-news";
import News from "../../components/ordinary/news/news";
import Filter from "../../components/ordinary/filter/filter";
import { AppRoute } from "../../core/constants/routes";
import { useAppDispatch, useAppSelector } from "../../core/hooks";
import { fetchDefaultPosts } from "../../core/store/api-actions";
import {
  getDefaultPosts,
  getDefaultPostsErrorStatus,
  getDefaultPostsLoadingStatus,
  getPostsFilterDateRangeStatus,
  getPostsFilterSportStatus,
  getSorterExclusiveStatus,
  getSorterTopStatus,
} from "../../core/store/default-posts-data/selectors";
import { POSTS_PER_PAGE } from "../../core/constants/common";
import Pagination from "../../components/ordinary/pagination/pagination";
import Loader from "../../components/simple/loader/loader";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ApiPostsDefaultGetIsExclusiveEnum,
  ApiPostsDefaultGetIsTopEnum,
} from "../../api";

function NewsPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const crumbsRoutes = [AppRoute.Main, AppRoute.NewsPage];
  const postsPerPage = POSTS_PER_PAGE;

  const searchParams = new URLSearchParams(location.search);
  const savedPage = parseInt(searchParams.get("page") || "1", 10);

  const [currentPage, setCurrentPage] = useState(savedPage);
  const [hasMorePosts, setHasMorePosts] = useState(true);

  const posts = useAppSelector(getDefaultPosts);
  const postsErrorStatus = useAppSelector(getDefaultPostsErrorStatus);
  const newsPageLoading = useAppSelector(getDefaultPostsLoadingStatus);
  const filterSportStatus = useAppSelector(getPostsFilterSportStatus);
  const sorterTopStatus = useAppSelector(getSorterTopStatus);
  const sorterExclusiveStatus = useAppSelector(getSorterExclusiveStatus);
  const dateRangeStatus = useAppSelector(getPostsFilterDateRangeStatus);

  useEffect(() => {
    setCurrentPage(1);
  }, [
    filterSportStatus,
    sorterTopStatus,
    sorterExclusiveStatus,
    dateRangeStatus,
  ]);

  useEffect(() => {
    const updateUrlParams = () => {
      const params = new URLSearchParams();
      params.set("page", currentPage.toString());

      if (filterSportStatus.value && filterSportStatus.value !== "all") {
        params.set("sport_kind_id", filterSportStatus.value.toString());
      }
      if (sorterTopStatus) {
        params.set("is_top", "1");
      }
      if (sorterExclusiveStatus) {
        params.set("is_exclusive", "1");
      }
      if (dateRangeStatus?.startDate) {
        params.set(
          "since",
          dateRangeStatus.startDate.toISOString().split("T")[0]
        );
      }
      if (dateRangeStatus?.endDate) {
        params.set("to", dateRangeStatus.endDate.toISOString().split("T")[0]);
      }
      navigate({
        pathname: location.pathname,
        search: params.toString(),
      });
    };

    updateUrlParams();
  }, [
    currentPage,
    filterSportStatus,
    sorterTopStatus,
    sorterExclusiveStatus,
    navigate,
    location.pathname,
  ]);

  useEffect(() => {
    const fetchPosts = async () => {
      const sportKindId = filterSportStatus.value !== "all" ? Number(filterSportStatus.value) : undefined;

      try {
        const result = await dispatch(
          fetchDefaultPosts({
            page: currentPage,
            pageSize: postsPerPage,
            sportKindId:
            sportKindId !== null && !isNaN(Number(sportKindId)) ? sportKindId : undefined,
            isTop: sorterTopStatus
              ? ApiPostsDefaultGetIsTopEnum.NUMBER_1
              : undefined,
            isExclusive: sorterExclusiveStatus
              ? ApiPostsDefaultGetIsExclusiveEnum.NUMBER_1
              : undefined,
            since: dateRangeStatus?.startDate?.toISOString().split("T")[0],
            to: dateRangeStatus?.endDate?.toISOString().split("T")[0],
          })
        ).unwrap();

        setHasMorePosts(result.length >= postsPerPage);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, [
    dispatch,
    currentPage,
    filterSportStatus,
    sorterTopStatus,
    sorterExclusiveStatus,
    dateRangeStatus,
  ]);

  const handlePageClick = (selectedPage: { selected: number }) => {
    const newPage = selectedPage.selected + 1;
    setCurrentPage(newPage);
    navigate(`${location.pathname}?page=${newPage}`);
    window.scrollTo(0, 0);
  };

  return (
    <div className="main__wrap">
      <Loader hidden={!newsPageLoading} />
      <div className={`gap gap--25 ${newsPageLoading ? "hidden" : ""}`}>
        <Crumbs routes={crumbsRoutes} />
        <div className="gap">
          <Title title="Новости" />
          <Filter
            postsErrorStatus={postsErrorStatus}
            posts={posts}
            filteredPosts={posts}
          />
          <News posts={posts} />
        </div>
        <Pagination
          pageCount={hasMorePosts ? currentPage + 1 : currentPage}
          currentPage={currentPage - 1}
          onPageClick={handlePageClick}
        />
      </div>
      <AsideNews />
    </div>
  );
}

export default NewsPage;
