import React from "react";
import Hashtags from "../hashtags/hashtags";
import {DefaultPost, Post} from "../../../api";
import {Link} from "react-router-dom";
import {AppRoute} from "../../../core/constants/routes";
import {simpleDateFormat} from "../../../core/utils/date-time-helpers";

function NewItem<T extends Post | DefaultPost>({post}: { post: T }) {
  const publishedDate: string | undefined = post.published_dt;
  const formattedDate: string | null = publishedDate ? simpleDateFormat(new Date(publishedDate), 'dd MMMM yyyy') : 'Дата не известна';

  return (
    <li className="new__item">
      <Link className="new__ref" to={`${AppRoute.NewsPage.url}/${post.post_id}`}>
        <img className="new__img" src={post._links?.image870x490?.href?.includes('image_placeholder_870x490.svg') ? '/images/defaults/news1.svg' : post._links?.image870x490?.href} width="490" height="270" alt={post.title}/>
        <div className="new__container">
          <p className="new__date">{formattedDate}</p>
          <p className="new__title">{post.title}</p>
          <p className="new__subtitle hide-400">{post.lead}</p>
          <Hashtags tags={post.tags}/>
        </div>
      </Link>
    </li>
  );
}

export default NewItem
