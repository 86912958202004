import React from "react";
import Reset from "../reset/reset";
import DateRangeFilter from "../date-range-filter/date-range-filter";
import {
  ItemsForFilter} from "../../../core/constants/filters";
import SportsFilter from "../sports-filter/sports-filter";

function FilterVideoItem() {
  return (
    <>
      <SportsFilter items={ItemsForFilter.Videos}/>
      <li className="filter__item">
        <DateRangeFilter isVideoFilter={true}/>
      </li>
      <li className="filter__item">
        <Reset items={ItemsForFilter.Videos}/>
      </li>
    </>
  );
}

export default FilterVideoItem;
