import React from "react";
import { HdbkSportKind } from "../../../api";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { filteringPostsBySport } from "../../../core/store/default-posts-data/default-posts-data";
import { filteringVideosBySport } from "../../../core/store/videos-data/videos-data";

function TagsItem({
  sport,
  onClick,
  isActive,
}: {
  sport: HdbkSportKind;
  onClick: () => void;
  isActive: boolean;
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleTagClick = (selectedOption: { value: string; label: string }) => {
    dispatch(filteringPostsBySport({ filterSportStatus: selectedOption }));
    dispatch(filteringVideosBySport({ filterSportStatus: selectedOption }));
    navigate(`/sport/${sport.sport_kind_id}`);
    onClick();
  };

  return (
    <li className={`tags__item ${isActive ? "active" : ""}`}>
      <a
        className="tags__link"
        onClick={() =>
          handleTagClick({
            value: sport.sport_kind_id.toString(),
            label: sport.title,
          })
        }
      >
        {/*<p className="tags__blue">#</p>*/}
        <p className="tags__text">{sport.title}</p>
      </a>
    </li>
  );
}

export default TagsItem;
