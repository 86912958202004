import React from "react";
import {DefaultPost, Post} from "../../../api";
import NewItem from "../new-item/new-item";



function NewList<T extends Post | DefaultPost>({posts}: { posts: T[] }) {
  return (
    <ul className={`new ${posts.length === 0 ? 'hidden' : ''}`}>
      {posts.map((post) => <NewItem key={post.post_id} post={post}/>)}
    </ul>
  );
}

export default NewList
