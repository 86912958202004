import React from "react";
import Texts from "../texts/texts";
import { Video } from "../../../api";
import FilterVideoItem from "../../smart/filter-video-item/filter-video-item";

function FilterVideo({
  videosErrorStatus,
  videos,
  filteredVideos,
}: {
  videosErrorStatus: boolean;
  videos: Video[];
  filteredVideos: Video[];
}) {
  return (
    <div className="gap">
      <ul className="filter filter--5">
        <FilterVideoItem />
      </ul>
      <Texts
        texts="Произошла ошибка при загрузке видео."
        hidden={!videosErrorStatus}
      />
      <Texts
        texts="По заданным параметрам информации не найдено."
        text="Попробуйте другие."
        hidden={!(videos.length > 0 || !filteredVideos.length)}
      />
    </div>
  );
}

export default FilterVideo;
