import React from "react";
import New from "../../components/smart/new/new";
import ContentVideo from "../../components/smart/content-video/content-video";
import Aside from "../../components/simple/aside/aside";
import Loader from "../../components/simple/loader/loader";
import { useAppSelector } from "../../core/hooks";
import { getPostsLoadingStatus } from "../../core/store/posts-data/selectors";
import { getVideosLoadingStatus } from "../../core/store/videos-data/selectors";
import { getWidgetTablesLoadingStatus } from "../../core/store/widget-tables-data/selectors";
import { getActualVoteLoadingStatus } from "../../core/store/votes-data/selectors";
import { useParams } from "react-router-dom";

function SportPage() {
  const { id: sportKindId } = useParams();
  const postsLoading = useAppSelector(getPostsLoadingStatus);
  const videosLoading = useAppSelector(getVideosLoadingStatus);
  const widgetTablesLoading = useAppSelector(getWidgetTablesLoadingStatus);
  const actualVoteLoading = useAppSelector(getActualVoteLoadingStatus);
  const asideLoading = widgetTablesLoading || actualVoteLoading;
  const sportPageLoading = postsLoading || videosLoading || asideLoading;

  return (
    <>
      <Loader hidden={!sportPageLoading} />
      <div className={`gap gap--50 ${sportPageLoading ? "hidden" : ""}`}>
        <div className="main__wrap">
          <div className="gap gap--60">
            <New sportKindId={Number(sportKindId)}/>
            <ContentVideo sportKindId={Number(sportKindId)}/>
          </div>
          <Aside />
        </div>
      </div>
    </>
  );
}

export default SportPage;
