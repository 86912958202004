import React, { useEffect, useState } from "react";
import TagsItem from "../tags-item/tags-item";
import { fetchSports } from "../../../core/store/api-actions";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { getHeaderSports } from "../../../core/store/sports-data/selectors";
import { ApiSportKindsGetOnMainEnum } from "../../../api";
import { useLocation } from 'react-router-dom'

function Tags({ className }: { className?: string }) {
  const dispatch = useAppDispatch();
  const sports = useAppSelector(getHeaderSports);
  const location = useLocation();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isMobileModal, setIsMobileModal] = useState(false);

  const pathParts = location.pathname.split("sport/");
  const sportKindId = pathParts[pathParts.length - 1];
  const queryParams = new URLSearchParams(location.search);
  const sportKindIdFromUrl = queryParams.get("sport_kind_id");

  useEffect(() => {
    dispatch(
      fetchSports({ pageSize: 10, onMain: ApiSportKindsGetOnMainEnum.NUMBER_1 })
    ); //Поправить ограничение количества

    const handleResize = () => {
      setIsMobileModal(window.innerWidth <= 1080);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [dispatch]);

  const handleToggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleCloseModal = () => {
    setDropdownVisible(false);
  };

  const handleItemClick = () => {
    setDropdownVisible(false);
  };

  const firstFive = sports.slice(0, 5);
  const remaining = sports.slice(5);

  return (
    <>
      <ul className={`tags ${className ? className : ""}`}>
        {firstFive.map((sport) => (
          <TagsItem
            sport={sport}
            key={sport.sport_kind_id}
            onClick={handleItemClick}
            isActive={sport.sport_kind_id === Number(sportKindId) || sport.sport_kind_id === Number(sportKindIdFromUrl)}
          />
        ))}

        {remaining.length > 0 && (
          <li className="tags__dropdown">
            <button onClick={handleToggleDropdown} className="tags__toggle">
              {dropdownVisible ? "Скрыть" : "Другое"}
              <svg
                className={`tags__icon ${
                  dropdownVisible ? "tags__icon--active" : ""
                }`}
                width="20"
                height="20"
              >
                <use xlinkHref="images/sprite.svg#arrow-left-blue" />
              </svg>
            </button>

            {dropdownVisible && !isMobileModal && (
              <ul className="tags__menu">
                {remaining.map((sport) => (
                  <TagsItem
                    sport={sport}
                    key={sport.sport_kind_id}
                    onClick={handleItemClick}
                    isActive={sport.sport_kind_id === Number(sportKindId) || sport.sport_kind_id === Number(sportKindIdFromUrl)}
                  />
                ))}
              </ul>
            )}
          </li>
        )}
      </ul>

      {dropdownVisible && isMobileModal && (
        <div className="modal" onClick={handleCloseModal}>
          <div className="" onClick={(e) => e.stopPropagation()}>
            <ul className="tags__menu">
              {remaining.map((sport) => (
                <TagsItem
                  sport={sport}
                  key={sport.sport_kind_id}
                  onClick={handleItemClick}
                  isActive={sport.sport_kind_id === Number(sportKindId) || sport.sport_kind_id === Number(sportKindIdFromUrl)}
                />
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default Tags;
