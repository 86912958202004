import React from "react";
import {useAppDispatch} from "../../../core/hooks";
import {resetPostsFilters} from "../../../core/store/default-posts-data/default-posts-data";
import {ItemsForFilter} from "../../../core/constants/filters";
import {resetEventsFilters} from "../../../core/store/events-data/events-data";
import {resetCompetitionsFilters} from "../../../core/store/competitions-data/competitions-data";
import {resetOrganizationsFilters} from "../../../core/store/organizations-data/organizations-data";
import { resetVideosFilters } from '../../../core/store/videos-data/videos-data'

function Reset({items}: { items: string }) {
  const dispatch = useAppDispatch();

  const handleReset = () => {
    switch (items) {
      case ItemsForFilter.Posts:
        dispatch(resetPostsFilters());
        break;
      case ItemsForFilter.Events:
        dispatch(resetEventsFilters());
        break;
      case ItemsForFilter.Competitions:
        dispatch(resetCompetitionsFilters());
        break;
      case ItemsForFilter.Organizations:
        dispatch(resetOrganizationsFilters());
        break;
      case ItemsForFilter.Videos:
        dispatch(resetVideosFilters());
        break;
      default:
        break;
    }
  };

  return (
    <a className="button reset" onClick={handleReset}>
      <svg className="reset__img" width="24" height="24">
        <use xlinkHref="images/sprite.svg#revert"/>
      </svg>
    </a>
  );
}

export default Reset;
