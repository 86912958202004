import React, { useEffect, useState } from "react";
import Crumbs from "../../components/ordinary/crumbs/crumbs";
import Title from "../../components/ordinary/title/title";
import Aside from "../../components/simple/aside/aside";
import Texts from "../../components/ordinary/texts/texts";
import { AppRoute } from "../../core/constants/routes";
import ContentListVideo from "../../components/ordinary/content-list-video/content-list-video";
import { useAppDispatch, useAppSelector } from "../../core/hooks";
import {
  getVideosFilterDateRangeStatus,
  getVideos,
  getVideosErrorStatus,
  getVideosFilterSportStatus,
  getVideosLoadingStatus,
} from "../../core/store/videos-data/selectors";
import { fetchVideos } from "../../core/store/api-actions";
import { VIDEOS_PER_PAGE } from "../../core/constants/common";
import Pagination from "../../components/ordinary/pagination/pagination";
import Loader from "../../components/simple/loader/loader";
import ButtonsPlaylist from "../../components/smart/buttons-playlist/buttons-playlist";
import { useLocation, useNavigate } from "react-router-dom";
import FilterVideo from "../../components/ordinary/filter-video/filter-video";

function VideosPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const crumbsRoutes = [AppRoute.Main, AppRoute.Videos];
  const videosPerPage = VIDEOS_PER_PAGE;

  const searchParams = new URLSearchParams(location.search);
  const savedPage = parseInt(searchParams.get("page") || "1", 10);

  const [currentPage, setCurrentPage] = useState(savedPage);
  const [hasMoreVideos, setHasMoreVideos] = useState(true);

  const videos = useAppSelector(getVideos);
  const videosError = useAppSelector(getVideosErrorStatus);
  const videosLoading = useAppSelector(getVideosLoadingStatus);
  const filterSportStatus = useAppSelector(getVideosFilterSportStatus);
  const dateRangeStatus = useAppSelector(getVideosFilterDateRangeStatus);

  useEffect(() => {
    setCurrentPage(1);
  }, [filterSportStatus, dateRangeStatus]);

  useEffect(() => {
    const updateUrlParams = () => {
      const params = new URLSearchParams();
      params.set("page", currentPage.toString());

      if (filterSportStatus.value && filterSportStatus.value !== "all") {
        params.set("sport_kind_id", filterSportStatus.value.toString());
      }
      if (dateRangeStatus?.startDate) {
        params.set(
          "since",
          dateRangeStatus.startDate.toISOString().split("T")[0]
        );
      }
      if (dateRangeStatus?.endDate) {
        params.set("to", dateRangeStatus.endDate.toISOString().split("T")[0]);
      }
      navigate({
        pathname: location.pathname,
        search: params.toString(),
      });
    };

    updateUrlParams();
  }, [currentPage, filterSportStatus, navigate, location.pathname]);

  useEffect(() => {
    const fetchGetVideos = async () => {
      const sportKindId =
        filterSportStatus.value !== "all"
          ? Number(filterSportStatus.value)
          : undefined;

      try {
        const result = await dispatch(
          fetchVideos({
            page: currentPage,
            pageSize: videosPerPage,
            sportKindId:
              sportKindId !== null && !isNaN(Number(sportKindId))
                ? sportKindId
                : undefined,
            since: dateRangeStatus?.startDate?.toISOString().split("T")[0],
            to: dateRangeStatus?.endDate?.toISOString().split("T")[0],
          })
        ).unwrap();

        setHasMoreVideos(result.length >= videosPerPage);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };
    fetchGetVideos();
  }, [dispatch, currentPage, filterSportStatus, dateRangeStatus]);

  const handlePageClick = (selectedPage: { selected: number }) => {
    const newPage = selectedPage.selected + 1;
    setCurrentPage(newPage);
    navigate(`${location.pathname}?page=${newPage}`);
    window.scrollTo(0, 0);
  };

  return (
    <div className="main__wrap">
      <Loader hidden={!videosLoading} />
      <div className={`gap gap--35 ${videosLoading ? "hidden" : ""}`}>
        <Crumbs routes={crumbsRoutes} />
        <div className="gap">
          <ButtonsPlaylist />
          <Title title="Видео" />
          <FilterVideo
            videosErrorStatus={videosError}
            videos={videos}
            filteredVideos={videos}
          />
          <ContentListVideo videos={videos} />
          <Texts
            texts="Здесь находятся видео нашего портала, но на данный момент они еще не опубликованы. Ожидайте, так как в скором времени они появятся!"
            hidden={videosError || videos.length > 0}
          />
          <Texts
            texts="Произошла ошибка при загрузке видео."
            hidden={!videosError}
          />
        </div>
        <Pagination
          pageCount={hasMoreVideos ? currentPage + 1 : currentPage}
          currentPage={currentPage - 1}
          onPageClick={handlePageClick}
        />
      </div>
      <Aside />
    </div>
  );
}

export default VideosPage;
