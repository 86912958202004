import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {fetchVideo, fetchVideos} from '../api-actions';
import {VideosState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";
import { Option } from '../../types/common'

const initialState: VideosState = {
  isVideosLoading: false,
  isVideoLoading: false,
  isVideosError: false,
  isVideoError: false,
  videos: [],
  currentVideo: null,
  playerVideos: [],
  filterSportStatus: {
    value: 'all',
    label: 'Все виды спорта'
  },
  filterDateRangeStatus: {
    startDate: null,
    endDate: null,
  },
};

export const videosData = createSlice({
  name: NameSpace.Videos,
  initialState,
  reducers: {
    filteringVideosBySport: (state, action: PayloadAction<{filterSportStatus: Option}>) => {
      const {filterSportStatus} = action.payload;
      state.filterSportStatus = filterSportStatus;
    },
    resetVideosFilters: (state) => {
      state.filterSportStatus = initialState.filterSportStatus;
      state.filterDateRangeStatus = initialState.filterDateRangeStatus;
    },
    setVideosDateFilter: (state, action: PayloadAction<{ startDate: Date | null; endDate: Date | null }>) => {
      const { startDate, endDate } = action.payload;
      state.filterDateRangeStatus.startDate = startDate;
      state.filterDateRangeStatus.endDate = endDate;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchVideos.pending, (state) => {
        state.isVideosLoading = true;
      })
      .addCase(fetchVideos.fulfilled, (state, action) => {
        if (action.meta.arg.inPlayer){
          state.playerVideos = action.payload
        } else {
          state.videos = action.payload;
        }
        state.isVideosLoading = false;
      })
      .addCase(fetchVideos.rejected, (state) => {
        state.isVideosError = true;
        state.isVideosLoading = false;
      })
      .addCase(fetchVideo.pending, (state) => {
        state.isVideoLoading = true;
      })
      .addCase(fetchVideo.fulfilled, (state, action) => {
        state.currentVideo = action.payload;
        state.isVideoLoading = false;
      })
      .addCase(fetchVideo.rejected, (state) => {
        state.isVideoError = true;
        state.isVideoLoading = false;
      });
  }
});

export const {
  filteringVideosBySport,
  resetVideosFilters,
  setVideosDateFilter
} = videosData.actions;
