import React from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './../../../assets/css/custom-react-datepicker.css';
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {setPostsDateFilter} from "../../../core/store/default-posts-data/default-posts-data";
import {getPostsFilterDateRangeStatus} from "../../../core/store/default-posts-data/selectors";
import {getMonth, getYear} from "date-fns";
import {ru} from "date-fns/locale";
import {
  filterMonths,
  yearsAsOptions
} from "../../../core/constants/filters";
import Select from "react-select";
import { getVideosFilterDateRangeStatus } from '../../../core/store/videos-data/selectors'
import { setVideosDateFilter } from '../../../core/store/videos-data/videos-data'

function DateRangeFilter({isVideoFilter}:{isVideoFilter:boolean}) {
  const dispatch = useAppDispatch();
  const dateRangeStatus = isVideoFilter
    ? useAppSelector(getVideosFilterDateRangeStatus)
    : useAppSelector(getPostsFilterDateRangeStatus);

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [startDate, endDate] = dates;
    if (isVideoFilter) {
      dispatch(setVideosDateFilter({ startDate, endDate }));
    } else {
      dispatch(setPostsDateFilter({ startDate, endDate }));
    }
  };

  return (
    <div className="date">
      <DatePicker
        selectsRange={true}
        startDate={dateRangeStatus?.startDate}
        endDate={dateRangeStatus?.endDate}
        onChange={(dates) => handleDateChange(dates as [Date | null, Date | null])}
        className="date__input"
        placeholderText="дд.мм.гггг - дд.мм.гггг"
        dateFormat="dd.MM.yyyy"
        locale={ru}
        maxDate={new Date()}
        showPopperArrow={false}
        renderCustomHeader={(
          {
            date,
            decreaseMonth,
            increaseMonth,
            changeMonth,
            changeYear,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled
          }) => (
          <div className="custom-header">
            <button className="custom-header__arrow" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              <svg className="custom-header__icon" width="20" height="10">
                <use xlinkHref="images/sprite.svg#arrow-white"/>
              </svg>
            </button>
            <div className="custom-header__column">
              <Select
                defaultValue={yearsAsOptions[0]}
                options={yearsAsOptions}
                isSearchable={false}
                placeholder="Выберите год"
                classNamePrefix="select"
                className="select"
                value={yearsAsOptions.find((option) => option.value === getYear(date))}
                onChange={(option) => changeYear(Number(option?.value))}
              />
              <ul className="custom-header__months">
                {filterMonths.map((option, index) => (
                  <li
                    key={option}
                    onClick={() => changeMonth(filterMonths.indexOf(option))}
                    className={`custom-header__month ${index === getMonth(date) ? 'custom-header__month--active' : ''}`}
                  >
                    {window.innerWidth <= 767 ? option : option.substring(0, 3)}
                  </li>
                ))}
              </ul>
            </div>
            <button className="custom-header__arrow" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              <svg className="custom-header__icon custom-header__icon--right" width="20" height="10">
                <use xlinkHref="images/sprite.svg#arrow-white"/>
              </svg>
            </button>
          </div>
        )}
      />
      <svg className="date__icon" width="20" height="20">
        <use xlinkHref="images/sprite.svg#calendar-blue"/>
      </svg>
    </div>
  );
}

export default DateRangeFilter;
