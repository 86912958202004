import NewList from "../../ordinary/new-list/new-list";
import ButtonLink from "../../ordinary/button-link/button-link";
import React, { useEffect } from "react";
import Title from "../../ordinary/title/title";
import { fetchDefaultPosts, fetchPosts } from "../../../core/store/api-actions";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import {
  getPostsErrorStatus,
  getPostsOnMain,
} from "../../../core/store/posts-data/selectors";
import Texts from "../../ordinary/texts/texts";
import { AppRoute } from "../../../core/constants/routes";
import { ApiPostsGetOnMainEnum, DefaultPost, Post } from "../../../api";
import { getDefaultPosts } from "../../../core/store/default-posts-data/selectors";

function New({ sportKindId }: { sportKindId?: number }) {
  const dispatch = useAppDispatch();
  const posts = sportKindId
    ? useAppSelector(getDefaultPosts as (state: unknown) => DefaultPost[])
    : (useAppSelector(
        getPostsOnMain as (state: unknown) => Post[]
      ) as DefaultPost[]);
  const postsErrorStatus = useAppSelector(getPostsErrorStatus);

  useEffect(() => {
    if (sportKindId) {
      dispatch(
        fetchDefaultPosts({
          page: 1,
          pageSize: 4,
          sportKindId: sportKindId,
        })
      );
    } else {
      dispatch(
        fetchPosts({ pageSize: 6, onMain: ApiPostsGetOnMainEnum.NUMBER_1 })
      );
    }
  }, [dispatch, sportKindId]);

  return (
    <div className="container container--center">
      <Title title="Новости" />
      <NewList posts={posts} />
      <Texts
        texts={
          sportKindId
            ? "По данному виду спорта новостей не найдено."
            : "На данный момент на главной странице отсутствуют новости."
        }
        hidden={postsErrorStatus || posts.length > 0}
      />
      <Texts
        texts="Произошла ошибка при загрузке новостей для главной страницы"
        hidden={!postsErrorStatus}
      />
      <ButtonLink
        to={AppRoute.NewsPage.url}
        hidden={postsErrorStatus || posts.length < 0}
      />
    </div>
  );
}

export default New;
