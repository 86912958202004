import React from "react";
import NavigationList from "../navigation-list/navigation-list";
import LogoProject from "../../ordinary/logo-project/logo-project";

function Navigation() {
  return (
    <div className="navigation">
      <div className="navigation__wrapper">
        <LogoProject className="hide"/>
        <NavigationList/>
      </div>
    </div>
  );
}

export default Navigation;
