import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppDispatch, State } from "../types/state.js";
import {
  Album,
  Banner,
  DefaultApi,
  EventPost,
  DefaultPost,
  HdbkPartner,
  HdbkSportKind,
  Post,
  Subscription,
  ApiPostsIdGetWithEnum,
  Video,
  Organization,
  Page,
  Competition,
  CompetitionTable,
  HdbkSeason,
  Vote,
  MatchPost,
  ApiVotesIdAnswerPost200Response,
  ApiSearchPostsGroupedGet200ResponseDefault,
  ApiSearchPostsGroupedGet200ResponseEvent,
  ApiSearchPostsGroupedGet200ResponseMatch,
  ApiPostsMatchIdGetWithEnum,
  Social,
  ApiPagesAliasGetAliasEnum,
  VideoPlaylist,
  ApiPostsGetOnMainEnum,
  ApiPostsGetInSliderEnum,
  ApiPostsGetInSidebarEnum,
  ApiSportKindsGetOnMainEnum,
  ApiVideosGetOnMainEnum,
  ApiVideosGetInPlayerEnum,
  ApiPostsDefaultGetIsExclusiveEnum,
  ApiPostsDefaultGetIsTopEnum,
} from "../../api";
import axios, { AxiosResponse } from "axios";
import { SendVoteType } from "../types/common";
import { PAGE, PAGE_SIZE } from "../constants/common";

export const fetchPosts = createAsyncThunk<
  Post[],
  {
    page?: number;
    pageSize?: number;
    onMain?: ApiPostsGetOnMainEnum;
    inSlider?: ApiPostsGetInSliderEnum;
    inSidebar?: ApiPostsGetInSidebarEnum;
  },
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>(
  "posts/fetchPosts",
  async (
    { page = PAGE, pageSize = PAGE_SIZE, onMain, inSlider, inSidebar },
    { extra: api }
  ) => {
    try {
      const response: AxiosResponse<Post[]> = await api.apiPostsGet(
        page,
        pageSize,
        onMain,
        inSlider,
        inSidebar
      );
      return response.data;
    } catch (error) {
      console.error("Ошибка при получении постов:", error);
      throw error;
    }
  }
);

export const fetchPost = createAsyncThunk<
  Post,
  { postId: number; _with: ApiPostsIdGetWithEnum[] },
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>("posts/fetchPost", async ({ postId, _with }, { extra: api }) => {
  try {
    const response: AxiosResponse<Post> = await api.apiPostsIdGet(
      postId,
      _with
    );
    return response.data;
  } catch (error) {
    console.error(
      `Ошибка при получении поста с идентификатором ${postId}:`,
      error
    );
    throw error;
  }
});

export const fetchDefaultPosts = createAsyncThunk<
  DefaultPost[],
  {
    page?: number;
    pageSize?: number;
    sportKindId?: number;
    isExclusive?: ApiPostsDefaultGetIsExclusiveEnum;
    isTop?: ApiPostsDefaultGetIsTopEnum;
    since?:string;
    to?:string;
  },
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>("defaultPosts/fetchDefaultPosts", async (params, { extra: api }) => {
  try {
    const {
      page = PAGE,
      pageSize = PAGE_SIZE,
      sportKindId,
      isExclusive,
      isTop,
      since,
      to,
    } = params;
    const response: AxiosResponse<DefaultPost[]> = await api.apiPostsDefaultGet(
      page,
      pageSize,
      sportKindId,
      isExclusive,
      isTop,
      since,
      to,
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении постов:", error);
    throw error;
  }
});

export const fetchDefaultPost = createAsyncThunk<
  DefaultPost,
  number,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>(
  "defaultPosts/fetchDefaultPost",
  async (defaultPostId: number, { extra: api }) => {
    try {
      const response: AxiosResponse<DefaultPost> =
        await api.apiPostsDefaultIdGet(defaultPostId);
      return response.data;
    } catch (error) {
      console.error(
        `Ошибка при получении поста с идентификатором ${defaultPostId}:`,
        error
      );
      throw error;
    }
  }
);

export const fetchPartners = createAsyncThunk<
  HdbkPartner[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>("partners/fetchPartners", async (_arg, { extra: api }) => {
  try {
    const response: AxiosResponse<HdbkPartner[]> = await api.apiPartnersGet(
      1,
      PAGE_SIZE
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении данных о партнерах:", error);
    throw error;
  }
});

export const fetchPartner = createAsyncThunk<
  HdbkPartner,
  number,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>("partners/fetchPartner", async (partnerId: number, { extra: api }) => {
  try {
    const response: AxiosResponse<HdbkPartner> = await api.apiPartnersIdGet(
      partnerId
    );
    return response.data;
  } catch (error) {
    console.error(
      `Ошибка при получении данных о партнере с идентификатором ${partnerId}:`,
      error
    );
    throw error;
  }
});

export const fetchSocials = createAsyncThunk<
  Social[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>("socials/fetchSocials", async (_arg, { extra: api }) => {
  try {
    const response: AxiosResponse<Social[]> = await api.apiSocialsSiteGet();
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении данных о партнерах:", error);
    throw error;
  }
});

export const fetchSocial = createAsyncThunk<
  Social,
  number,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>("socials/fetchSocial", async (socialId: number, { extra: api }) => {
  try {
    const response: AxiosResponse<Social> = await api.apiSocialsIdGet(socialId);
    return response.data;
  } catch (error) {
    console.error(
      `Ошибка при получении данных о социальной сети с идентификатором ${socialId}:`,
      error
    );
    throw error;
  }
});

export const fetchAlbums = createAsyncThunk<
  Album[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>("albums/fetchAlbums", async (_arg, { extra: api }) => {
  try {
    const response: AxiosResponse<Album[]> = await api.apiAlbumsGet(
      1,
      PAGE_SIZE
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении альбомов:", error);
    throw error;
  }
});

export const fetchAlbum = createAsyncThunk<
  Album,
  number,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>("albums/fetchAlbum", async (albumId: number, { extra: api }) => {
  try {
    const response: AxiosResponse<Album> = await api.apiAlbumsIdGet(albumId);
    return response.data;
  } catch (error) {
    console.error(
      `Ошибка при получении альбома с идентификатором ${albumId}:`,
      error
    );
    throw error;
  }
});

export const fetchVideos = createAsyncThunk<
  Video[],
  {
    page?: number;
    pageSize?: number;
    onMain?: ApiVideosGetOnMainEnum;
    inPlayer?: ApiVideosGetInPlayerEnum;
    sportKindId?: number;
    since?:string;
    to?:string;
  },
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>(
  "videos/fetchVideos",
  async (
    params,
    { extra: api }
  ) => {
    try {
      const {
        page = PAGE,
        pageSize = PAGE_SIZE,
        onMain,
        inPlayer,
        sportKindId,
        since,
        to,
      } = params;
      const response: AxiosResponse<Video[]> = await api.apiVideosGet(
        page,
        pageSize,
        onMain,
        inPlayer,
        sportKindId,
        since,
        to,
      );
      return response.data;
    } catch (error) {
      console.error("Ошибка при получении видео:", error);
      throw error;
    }
  }
);

export const fetchVideo = createAsyncThunk<
  Video,
  number,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>("videos/fetchVideo", async (videoId: number, { extra: api }) => {
  try {
    const response: AxiosResponse<Video> = await api.apiVideosIdGet(videoId);
    return response.data;
  } catch (error) {
    console.error(
      `Ошибка при получении видео с идентификатором ${videoId}:`,
      error
    );
    throw error;
  }
});

export const fetchPages = createAsyncThunk<
  Page[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>("pages/fetchPages", async (_arg, { extra: api }) => {
  try {
    const response: AxiosResponse<Page[]> = await api.apiPagesGet();
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении страниц:", error);
    throw error;
  }
});

export const fetchPage = createAsyncThunk<
  Page,
  number,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>("pages/fetchPage", async (pageId: number, { extra: api }) => {
  try {
    const response: AxiosResponse<Page> = await api.apiPagesIdGet(pageId);
    return response.data;
  } catch (error) {
    console.error(
      `Ошибка при получении страницы с идентификатором ${pageId}:`,
      error
    );
    throw error;
  }
});

export const fetchPageAlias = createAsyncThunk<
  Page,
  string,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>("pages/fetchPageAlias", async (alias: string, { extra: api }) => {
  try {
    const response: AxiosResponse<Page> = await api.apiPagesAliasGet(
      alias as ApiPagesAliasGetAliasEnum
    );
    return response.data;
  } catch (error) {
    console.error(`Ошибка при получении страницы с алиасом ${alias}:`, error);
    throw error;
  }
});

export const fetchBanners = createAsyncThunk<
  Banner[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>("banners/fetchBanners", async (_arg, { extra: api }) => {
  try {
    const response: AxiosResponse<Banner[]> = await api.apiBannersGet(
      1,
      PAGE_SIZE
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении баннеров:", error);
    throw error;
  }
});

export const fetchBanner = createAsyncThunk<
  Banner,
  number,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>("banners/fetchBanner", async (bannerId: number, { extra: api }) => {
  try {
    const response: AxiosResponse<Banner> = await api.apiBannersIdGet(bannerId);
    return response.data;
  } catch (error) {
    console.error(
      `Ошибка при получении баннера с идентификатором ${bannerId}:`,
      error
    );
    throw error;
  }
});

export const fetchOrganizations = createAsyncThunk<
  Organization[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>("organizations/fetchOrganizations", async (_arg, { extra: api }) => {
  try {
    const response: AxiosResponse<Organization[]> =
      await api.apiOrganizationsGet(1, PAGE_SIZE);
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении школ-организаций:", error);
    throw error;
  }
});

export const fetchOrganization = createAsyncThunk<
  Organization,
  number,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>(
  "organizations/fetchOrganization",
  async (organizationId: number, { extra: api }) => {
    try {
      const response: AxiosResponse<Organization> =
        await api.apiOrganizationsIdGet(organizationId);
      return response.data;
    } catch (error) {
      console.error(
        `Ошибка при получении школу-организацию с идентификатором ${organizationId}:`,
        error
      );
      throw error;
    }
  }
);

export const sendSubscription = createAsyncThunk<
  void,
  Subscription,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>("subscriptions/sendSubscription", async (subscription, { extra: api }) => {
  try {
    await api.apiSubscriptionSubscribePost(subscription.email);
  } catch (error) {
    console.error("Ошибка при отправке подписки:", error);
    throw error;
  }
});

export const fetchSports = createAsyncThunk<
  HdbkSportKind[],
  {
    page?: number;
    pageSize?: number;
    onMain?: ApiSportKindsGetOnMainEnum;
  },
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>(
  "sports/fetchSports",
  async ({ page = PAGE, pageSize = PAGE_SIZE, onMain }, { extra: api }) => {
    try {
      const response: AxiosResponse<HdbkSportKind[]> =
        await api.apiSportKindsGet(page, pageSize, onMain);
      return response.data;
    } catch (error) {
      console.error("Ошибка при получении видов спорта:", error);
      throw error;
    }
  }
);

export const fetchSeasons = createAsyncThunk<
  HdbkSeason[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>("seasons/fetchSeasons", async (_arg, { extra: api }) => {
  try {
    const response: AxiosResponse<HdbkSeason[]> = await api.apiSeasonsGet(
      1,
      PAGE_SIZE
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении сезонов:", error);
    throw error;
  }
});

export const fetchVotes = createAsyncThunk<
  Vote[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>("votes/fetchVotes", async (_arg, { extra: api }) => {
  try {
    const response: AxiosResponse<Vote[]> = await api.apiVotesGet(1, PAGE_SIZE);
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении опросов:", error);
    throw error;
  }
});

export const fetchVote = createAsyncThunk<
  Vote,
  number,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>("votes/fetchVote", async (voteId: number, { extra: api }) => {
  try {
    const response: AxiosResponse<Vote> = await api.apiVotesIdGet(voteId);
    return response.data;
  } catch (error) {
    console.error(
      `Ошибка при получении опроса с идентификатором ${voteId}:`,
      error
    );
    throw error;
  }
});

export const fetchActualVote = createAsyncThunk<
  Vote,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>("votes/fetchActualVote", async (_arg, { extra: api }) => {
  try {
    const response: AxiosResponse<Vote> = await api.apiVotesActualGet();
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении актуального опроса:", error);
    throw error;
  }
});

export const sendVote = createAsyncThunk<
  ApiVotesIdAnswerPost200Response,
  SendVoteType,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>("votes/sendVote", async (sendVote, thunkApi) => {
  const { extra: api } = thunkApi;
  try {
    if (sendVote.vote_id !== null && sendVote.option_id !== null) {
      const response = await api.apiVotesIdAnswerPost(
        sendVote.vote_id,
        sendVote.option_id,
        sendVote.ip
      );
      return response.data;
    } else {
      throw new Error("Неверные значения vote_id или option_id");
    }
  } catch (error) {
    console.error("Ошибка при отправке голоса:", error);
    throw error;
  }
});

export const fetchIpAddress = async (): Promise<string> => {
  try {
    const response = await axios.get("https://api64.ipify.org?format=json");
    return response.data.ip;
  } catch (error) {
    console.error("Ошибка при получении IP-адреса:", error);
    throw error;
  }
};
export const fetchEventPosts = createAsyncThunk<
  EventPost[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>("eventPosts/fetchEventPosts", async (_arg, { extra: api }) => {
  try {
    const response: AxiosResponse<EventPost[]> = await api.apiPostsEventGet(
      1,
      PAGE_SIZE
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении постов:", error);
    throw error;
  }
});

export const fetchEventPost = createAsyncThunk<
  EventPost,
  number,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>("eventPosts/fetchEventPost", async (postId: number, { extra: api }) => {
  try {
    const response: AxiosResponse<EventPost> = await api.apiPostsEventIdGet(
      postId
    );
    return response.data;
  } catch (error) {
    console.error(
      `Ошибка при получении поста с идентификатором ${postId}:`,
      error
    );
    throw error;
  }
});

export const fetchSearchedPosts = createAsyncThunk<
  ApiSearchPostsGroupedGet200ResponseDefault,
  string,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>("searches/fetchSearchedPosts", async (query: string, { extra: api }) => {
  try {
    const response: AxiosResponse<ApiSearchPostsGroupedGet200ResponseDefault> =
      await api.apiSearchPostsDefaultGet(query, PAGE_SIZE);
    return response.data;
  } catch (error) {
    console.error(
      `Ошибка при выполнении поискового запроса "${query}" по новостям:`,
      error
    );
    throw error;
  }
});

export const fetchSearchedEvents = createAsyncThunk<
  ApiSearchPostsGroupedGet200ResponseEvent,
  string,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>("searches/fetchSearchedEvents", async (query: string, { extra: api }) => {
  try {
    const response: AxiosResponse<ApiSearchPostsGroupedGet200ResponseEvent> =
      await api.apiSearchPostsEventGet(query, PAGE_SIZE);
    return response.data;
  } catch (error) {
    console.error(
      `Ошибка при выполнении поискового запроса "${query}" по мероприятьям:`,
      error
    );
    throw error;
  }
});

export const fetchSearchedMatches = createAsyncThunk<
  ApiSearchPostsGroupedGet200ResponseMatch,
  string,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>("searches/fetchSearchedMatches", async (query: string, { extra: api }) => {
  try {
    const response: AxiosResponse<ApiSearchPostsGroupedGet200ResponseMatch> =
      await api.apiSearchPostsMatchGet(query, PAGE_SIZE);
    return response.data;
  } catch (error) {
    console.error(
      `Ошибка при выполнении поискового запроса "${query}" по матчам:`,
      error
    );
    throw error;
  }
});

export const fetchCompetitions = createAsyncThunk<
  Competition[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>("competitions/fetchCompetitions", async (_arg, { extra: api }) => {
  try {
    const response: AxiosResponse<Competition[]> = await api.apiCompetitionsGet(
      1,
      PAGE_SIZE
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении соревнований:", error);
    throw error;
  }
});

export const fetchCompetition = createAsyncThunk<
  Competition,
  number,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>(
  "competitions/fetchCompetition",
  async (competitionId: number, { extra: api }) => {
    try {
      const response: AxiosResponse<Competition> =
        await api.apiCompetitionsIdGet(competitionId);
      return response.data;
    } catch (error) {
      console.error(
        `Ошибка при получении соревнования с идентификатором ${competitionId}:`,
        error
      );
      throw error;
    }
  }
);
export const fetchCompetitionsTables = createAsyncThunk<
  CompetitionTable[],
  number,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>(
  "competitionsTables/fetchCompetitionsTables",
  async (competitionId: number, { extra: api }) => {
    try {
      const response: AxiosResponse<CompetitionTable[]> =
        await api.apiCompetitionsIdTablesGet(competitionId);
      return response.data;
    } catch (error) {
      console.error(
        `Ошибка при получении таблиц соревнования с идентификатором ${competitionId}:`,
        error
      );
      throw error;
    }
  }
);

export const fetchWidgetTables = createAsyncThunk<
  CompetitionTable[],
  number,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>(
  "widgetTables/fetchWidgetTables",
  async (competitionId: number, { extra: api }) => {
    try {
      const response: AxiosResponse<CompetitionTable[]> =
        await api.apiCompetitionsIdTablesGet(competitionId);
      return response.data;
    } catch (error) {
      console.error(
        `Ошибка при получении таблиц соревнования с идентификатором ${competitionId}:`,
        error
      );
      throw error;
    }
  }
);

export const fetchCompetitionTable = createAsyncThunk<
  CompetitionTable,
  { competitionId: number; tableId: number },
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>(
  "competitions/fetchCompetitionTable",
  async ({ competitionId, tableId }, { extra: api }) => {
    try {
      const response: AxiosResponse<CompetitionTable> =
        await api.apiCompetitionsIdTablesTableIdGet(competitionId, tableId);
      return response.data;
    } catch (error) {
      console.error(
        `Ошибка при получении таблицы с идентификатором ${tableId} соревнования с идентификатором ${competitionId}:`,
        error
      );
      throw error;
    }
  }
);
export const fetchCompetitionsMatches = createAsyncThunk<
  MatchPost[],
  number,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>(
  "competitionsMatches/fetchCompetitionsMatches",
  async (competitionId: number, { extra: api }) => {
    try {
      const response: AxiosResponse<MatchPost[]> =
        await api.apiCompetitionsIdMatchesGet(competitionId);
      return response.data;
    } catch (error) {
      console.error(
        `Ошибка при получении матчей соревнования с идентификатором ${competitionId}:`,
        error
      );
      throw error;
    }
  }
);

export const fetchCompetitionMatch = createAsyncThunk<
  MatchPost,
  { competitionId: number; matchId: number },
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>(
  "competitions/fetchCompetitionTable",
  async ({ competitionId, matchId }, { extra: api }) => {
    try {
      const response: AxiosResponse<MatchPost> =
        await api.apiCompetitionsIdTablesTableIdGet(competitionId, matchId);
      return response.data;
    } catch (error) {
      console.error(
        `Ошибка при получении матча с идентификатором ${matchId} соревнования с идентификатором ${competitionId}:`,
        error
      );
      throw error;
    }
  }
);

export const fetchMatchPosts = createAsyncThunk<
  MatchPost[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>("matchPosts/fetchMatchPosts", async (_arg, { extra: api }) => {
  try {
    const response: AxiosResponse<MatchPost[]> = await api.apiPostsMatchGet(
      1,
      PAGE_SIZE
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении постов:", error);
    throw error;
  }
});

export const fetchMatchPost = createAsyncThunk<
  MatchPost,
  { postId: number; _with: ApiPostsMatchIdGetWithEnum[] },
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>("matchPosts/fetchMatchPost", async ({ postId, _with }, { extra: api }) => {
  try {
    const response: AxiosResponse<MatchPost> = await api.apiPostsMatchIdGet(
      postId,
      _with
    );
    return response.data;
  } catch (error) {
    console.error(
      `Ошибка при получении поста с идентификатором ${postId}:`,
      error
    );
    throw error;
  }
});

export const fetchVideoPlaylists = createAsyncThunk<
  VideoPlaylist[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>("videoPlaylists/fetchVideoPlaylists", async (_arg, { extra: api }) => {
  try {
    const response: AxiosResponse<VideoPlaylist[]> = await api.apiPlaylistsGet(
      1,
      PAGE_SIZE
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении плейлистов:", error);
    throw error;
  }
});

export const fetchVideoPlaylist = createAsyncThunk<
  VideoPlaylist,
  number,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>(
  "videoPlaylists/fetchVideoPlaylist",
  async (playlistId: number, { extra: api }) => {
    try {
      const response: AxiosResponse<VideoPlaylist> =
        await api.apiPlaylistsIdGet(playlistId);
      return response.data;
    } catch (error) {
      console.error(
        `Ошибка при получении плейлиста с идентификатором ${playlistId}:`,
        error
      );
      throw error;
    }
  }
);
